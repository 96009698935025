<template>
  <div class="login-container">
    <h2>用户登录</h2>
    <div class="input-group">
      <input type="text" v-model="username" placeholder="用户名">
    </div>
    <div class="input-group">
      <input type="password" v-model="password" placeholder="密码">
    </div>
    <div class="input-group">
      <button @click="login">登录</button>
    </div>
    <p v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return { username: "", password: "", errorMessage: "" }
  },
  methods: {
    async login() {
      try {
        const res = await axios.post("/api/login", { username: this.username, password: this.password })
        if (res.data.token) {
          localStorage.setItem("token", res.data.token)
          this.$router.push("/files")
        }
      } catch (error) {
        this.errorMessage = "登录失败，请检查用户名和密码！"
      }
    }
  }
}
</script>

<style>
.login-container {
  text-align: center;
  margin-top: 50px;
}

.input-group {
  margin-bottom: 10px; /* 增加间距 */
}

input {
  width: 200px;
  padding: 8px;
  font-size: 16px;
}

button {
  padding: 8px 16px;
  font-size: 16px;
}
</style>
