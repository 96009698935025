<template>
  <div>
    <h2>文件列表</h2>
    <ul>
      <li v-for="file in files" :key="file.id">
        {{ file.name }} - <button @click="download(file.name)">下载</button>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "FileList",
  data() {
    return {
      files: []
    }
  },
  async created() {
    const token = localStorage.getItem("token");
    
    // ✅ 如果 token 为空，自动跳转到登录页面
    if (!token) {
      alert("请先登录！");
      window.location.href = "/";
      return;
    }

    try {
      const response = await axios.get('/api/files', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      this.files = response.data;
    } catch (error) {
      console.error("获取文件列表失败", error);
      if (error.response && error.response.status === 401) {
        alert("登录已过期，请重新登录！");
        window.location.href = "/login";
      }
    }
  },

methods: {
  async download(fileName) {
    try {
      const token = localStorage.getItem("token");
      window.open(`/api/file/${fileName}/download?token=${token}`, "_blank");
    } catch (error) {
      console.error("下载失败", error);
    }
  }
}

}
</script>
