import { createRouter, createWebHistory } from 'vue-router'
import UserLogin from '../views/UserLogin.vue'
import FileList from '../views/FileList.vue'

const routes = [
  { path: '/', component: UserLogin },
  { path: '/files', component: FileList }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
